@charset "utf-8";
/* @group Public */
html{font-size: 10px;}
body,div,ul,ol,dl,li,dt,dd,h1,h2,h3,h4,p,form,iframe,input,textarea,a,span,em,strong,img,html,nav,header,article,button,footer,var {padding:0;margin:0;}
body { font:12px/1.2rem "Microsoft YaHei",tahoma,arial,sans-serif;position:relative; -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; margin: 0; padding: 0;}
form,input {background:none;border:none;}
ul,dl,ol {list-style-type:none;}
h1, h2, h3, h4, h5 { font:12px/1.2rem "Microsoft YaHei",arial,tahoma; }
a { text-decoration:none; }
a:hover,a:focus { outline:none; }
table { border-collapse:collapse;border-spacing:0; }
img { border:none; }
strong,b { font-weight:normal; }
em,i,var { font-style:normal; }
p { text-indent:0; }
code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }
.clear { clear:both;height:0;line-height:0;overflow:hidden;width:0; }
.clearfix:after { clear:both;content:".";display:block;font-size:0;height:0;visibility:hidden; }
@media screen and (min-width: 320px) {
    html {
        font-size: calc(320px /75);
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: calc(360px / 75);
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: calc(375px / 75);
    }
}
@media screen and (min-width: 384px) {
    html {
        font-size: calc(384px / 75);
    }
}
@media screen and (min-width: 400px) {
    html {
        font-size: calc(400px /75);
    }
}
@media screen and (min-width: 414px) {
    html {
        font-size: calc(414px / 75);
    }
}
@media screen and (min-width: 424px) {
    html {
        font-size: calc(424px / 75);
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: calc(480px / 75);
    }
}
@media screen and (min-width: 540px) {
    html {
        font-size: calc(540px / 75);
    }
}
@media screen and (min-width: 720px) {
    html {
        font-size: calc(720px / 75);
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: calc(750px / 75);
    }
}
body {
    width: 100%; height: 100%; background: #f9f9f9;
}
#root, #App-container {
    display: flex;
    /* flex: 1; */
    /*width: 100vw;*/
    /*height: 100vh;*/
    width: 100%;
    height: 100%;
}